import { Link } from "react-router-dom";
import { AiFillHome, AiOutlineCalculator} from "react-icons/ai";
import { FaTemperatureHigh } from "react-icons/fa";

import "./pagestyle.css"
 
export default function Navigation () {
    return (
        <div className="navwrapper">
            <Link className="icon" to={"/home"}><AiFillHome /></Link>
            <Link className="icon" to={"/calculator"}><AiOutlineCalculator /></Link>
            <Link className="icon" to={"/temperature"}><FaTemperatureHigh /></Link>
        </div>
    );
};