import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

import  "./topbar.css"


export default function Topbar (){
  return (
    <div className="topbar">
        <Link className="menue" to={"/"}><AiOutlineMenu /></Link>
        <p className="header">Experimental Tools</p>
    </div>
  );
};