import React, { useState } from "react";
import "./pagestyle.css"


const Temperature = () => {
    let [temp, setTemp] = useState({
        c: 0,
        f: 0,
        k: 0,
    });

    const CelsiusHandler = (e) => {
        e.preventDefault();
        if(e.target.value!==""){
            const value = parseFloat(e.target.value);
            setTemp(({
                ...temp,
                c: value,
                f: value*1.8+32,
                k: value+273.15
            }));
        }
        else{
            const value = e.target.value;
            setTemp({
                ...temp,
                c: value
            });
        }
    };

    const FahrenheitHandler = (e) => {
        e.preventDefault();
        if(e.target.value!==""){
            const value = parseFloat(e.target.value);
            setTemp({
                ...temp,
                c: (value/1.8)-32,
                f: value,
                k: (value/1.8-32)+273.15
            });
        }
        else{
            const value = e.target.value;
            setTemp({
                ...temp,
                f: value
            });
        }
    };

    const KelvinHandler = (e) => {
        e.preventDefault();
        if(e.target.value!==""){
            const value = parseFloat(e.target.value);
            setTemp({
                ...temp,
                c: value-273.15,
                f: (value-273.15)*1.8+32,
                k: value
            });
    
            console.log("temp.key");
        }
        else{
            const value = e.target.value;
            setTemp({
                ...temp,
                k: value
            });
        }
    };

    return (
        <div className="tempwrapper">
            <p className="text">Grad Celsius:</p>
                <input  value={temp.c} onChange={CelsiusHandler} className="input" label="Outlined" variant="outlined" />
                <p className="text">Grad Fahrenheit:</p>
                <input  value={temp.f} onChange={FahrenheitHandler} className="input" label="Outlined" variant="outlined" />
                <p className="text">Grad Kelvin:</p>
                <input  value={temp.k} onChange={KelvinHandler} className="input" label="Outlined" variant="outlined" />
        </div>
    );
};
 
export default Temperature;