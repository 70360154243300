import React from "react";
import {BrowserRouter,Routes,Route} from "react-router-dom";

import Sidebar from "./components/sidebar";
import Topbar from "./components/topbar";

import Navigation from "./pages/navigation";
import Home from "./pages/home";
import Temperature from "./pages/temperature";
import Calculator from "./pages/calculator";
 
export default function App() {
    return (
      <div className="background">
        <BrowserRouter>
          <Topbar />
          <Sidebar />
          <Routes>
              <Route exact path="/" element={<Navigation />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/calculator" element={<Calculator />} />
              <Route exact path="/temperature" element={<Temperature />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
}