import { Link } from "react-router-dom";
import { AiFillHome, AiOutlineCalculator} from "react-icons/ai";
import { FaTemperatureHigh } from "react-icons/fa";


import  "./sidebar.css"


export default function Sidebar (){
  return (
    <div className="sidebar">
        <Link className="element" to={"/home"}><i><AiFillHome /></i> Home</Link>
        <Link className="element" to={"/calculator"}><i><AiOutlineCalculator /></i> Calculator</Link>
        <Link className="element" to={"/temperature"}><i><FaTemperatureHigh /></i> Temperature</Link>
    </div>
  );
};