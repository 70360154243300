import React, { useState, useEffect } from 'react';
import "./pagestyle.css"
 
export default function Home () {
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
      fetch('/api/time').then(res => res.json()).then(data => {
        setCurrentTime(data.time);
      });
    }, []);
    
    return (
      <div className="textwrapper">
            <p className="header">Welcome to my Page.</p><br></br>
            <p className="text">The current time is {currentTime}.</p>
      </div>
    );
};